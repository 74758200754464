// @ts-ignore
import { PoolConfig, QuoteToken } from './types'

const farms: PoolConfig[] = [
  // {
  //   sousId: 0,
  //   tokenName: 'DAI-MATIC',
  //   tokenAdressInLp: '0xdFBc69639C3816CFc6895552E594a23Abc12FA1a',
  //   tokenAddress: '0x97276399d28943dcc98b801a7EF0db647B8fB0bD',
  //   stakingTokenName: QuoteToken.DAI,
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   stakingTokenAddress: '0x0d4fe4Ab6123F7F1A5fb280B2B22AC6F7d1c1D81',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0x3B7d6C7E80955FdAD732C0E711482Fe826fb0247',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'matic-network',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: false,
  //   isTokenOnly: false
  // },
  // {
  //   sousId: 1,
  //   tokenName: 'USDC-MATIC',
  //   tokenAdressInLp: '0x284F81e23cf7D9B91D42A94395ff60E28365aEdC',
  //   tokenAddress: '0x97276399d28943dcc98b801a7EF0db647B8fB0bD',
  //   stakingTokenName: QuoteToken.USDC,
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   stakingTokenAddress: '0x70f807717252d5e886FaFD4FE492aCbb85cadB5D',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0x3B7d6C7E80955FdAD732C0E711482Fe826fb0247',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'matic-network',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: false,
  //   isTokenOnly: false
  // },
  // {
  //   sousId: 2,
  //   tokenName: 'USDT-MATIC',
  //   tokenAdressInLp: '0xa5e964afe67fc1a5364e661df92f1a744ef6BF0C',
  //   tokenAddress: '0x97276399d28943dcc98b801a7EF0db647B8fB0bD',
  //   stakingTokenName: QuoteToken.USDT,
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   stakingTokenAddress: '0x8Fc3e620762B6E76D53556A93c6FcF3E04e8d9FE',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0x3B7d6C7E80955FdAD732C0E711482Fe826fb0247',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'matic-network',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: false,
  //   isTokenOnly: false
  // },
  // {
  //   sousId: 3,
  //   tokenName: 'WorldOfDefi',
  //   tokenAdressInLp: '0xa5e964afe67fc1a5364e661df92f1a744ef6BF0C',
  //   tokenAddress: '0x97276399d28943dcc98b801a7EF0db647B8fB0bD',
  //   stakingTokenName: QuoteToken.WORLDOFDEFI,
  //   quoteTokenSymbol: QuoteToken.WORLDOFDEFI,
  //   stakingTokenAddress: '0x85C5FA37CD3f8616c6318df58549f34039278C61',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0x3B7d6C7E80955FdAD732C0E711482Fe826fb0247',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'matic-network',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: false,
  //   isTokenOnly: true
  // }
  // {
  //   sousId: 0,
  //   tokenName: 'USDT-MATIC',
  //   tokenAdressInLp: '0xa5e964afe67fc1a5364e661df92f1a744ef6BF0C',
  //   tokenAddress: '0x97276399d28943dcc98b801a7EF0db647B8fB0bD',
  //   stakingTokenName: QuoteToken.USDT,
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   stakingTokenAddress: '0x97d5d4123F5C8e24644985593B2AE0C17cd11924',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0xB43b1A131097Fb0A16e656f7ca30f623196fadFC',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'tether',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: false
  // },
  // {
  //   sousId: 2,
  //   tokenName: 'USDC-MATIC',
  //   tokenAdressInLp: '0x284F81e23cf7D9B91D42A94395ff60E28365aEdC',
  //   tokenAddress: '0x97276399d28943dcc98b801a7EF0db647B8fB0bD',
  //   stakingTokenName: QuoteToken.USDC,
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   stakingTokenAddress: '0xd33c9E0Df59E2A32B75eA577b2AaCa5C9d9621CE',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0x6EB50c8B582140Feab1614c0b915eE78e90888F5',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'usd-coin',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: false
  // },
  // {
  //   sousId: 3,
  //   tokenName: 'DAI-MATIC',
  //   tokenAdressInLp: '0xdFBc69639C3816CFc6895552E594a23Abc12FA1a',
  //   tokenAddress: '0x97276399d28943dcc98b801a7EF0db647B8fB0bD',
  //   stakingTokenName: QuoteToken.DAI,
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   stakingTokenAddress: '0xB4BA7BB24013Bf04506a17CDD40c749E81cbdDA8',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0xaDeD38D34ca5017F5D124d76c409ADC6fDE382c0',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'dai',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: false
  // },
  // {
  //   sousId: 4,
  //   tokenName: 'DAI-USDC',
  //   tokenAdressInLp: '0xdFBc69639C3816CFc6895552E594a23Abc12FA1a',
  //   tokenAddress: '0x284F81e23cf7D9B91D42A94395ff60E28365aEdC',
  //   stakingTokenName: QuoteToken.DAI,
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   stakingTokenAddress: '0x831c52f7B0fE5E17096f2eA2496D37A8C330E279',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0xE6C3186ffD082dfF8bc8916aF91c38d723C3caFb',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'dai',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: false
  // },
  // {
  //   sousId: 5,
  //   tokenName: 'DAI-USDT',
  //   tokenAdressInLp: '0xdFBc69639C3816CFc6895552E594a23Abc12FA1a',
  //   tokenAddress: '0xa5e964afe67fc1a5364e661df92f1a744ef6BF0C',
  //   stakingTokenName: QuoteToken.DAI,
  //   quoteTokenSymbol: QuoteToken.USDT,
  //   stakingTokenAddress: '0x833cFe4a9704DC5076125eDca02af6789c5d08d8',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0x5e9adB738039C38ED73367d93d228A9c10AB326C',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'dai',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: false
  // }
]
// export default farms
// @ts-ignore
// import { PoolConfig, QuoteToken } from './types'

// const farms: PoolConfig[] = [
// {
//   sousId: 0,
//   tokenName: 'CHLI-MATIC',
//   tokenAdressInLp: '0x97276399d28943dcc98b801a7EF0db647B8fB0bD',
//   tokenAddress: '0xDf94B2562331cE0e469f66d517c29c1F5751399D',
//   stakingTokenName: QuoteToken.CHLI,
//   quoteTokenSymbol: QuoteToken.MATIC,
//   stakingTokenAddress: '0x6166E00CE263E76B821a138D995477448451f239',
//   contractAddress: {
//     97: '',
//     56: '',
//     80001: '0xFe734c653fa88ad33fc2CD56fCA088D2e87239e2',
//     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
//     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
//     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
//   },
//   quoteTokenCoinGeckoId: 'matic-network',
//   harvest: true,
//   tokenPerBlock: '1',
//   sortOrder: 0,
//   isFinished: false,
//   tokenDecimals: 18,
//   isVault: false
// }
// {
//   sousId: 1,
//   tokenName: 'MATIC-USDT',
//   tokenAdressInLp: '0xBEd0e70eE8488ab8828705fFADff66FBC147973B',
//   tokenAddress: '0x97276399d28943dcc98b801a7EF0db647B8fB0bD',
//   stakingTokenName: QuoteToken.MATIC,
//   quoteTokenSymbol: QuoteToken.USDT,
//   stakingTokenAddress: '0x5C38B9Bd4D9f893015344C3085D4212F51536B71',
//   contractAddress: {
//     97: '',
//     56: '',
//     80001: '0x811C99eB6B9B466a39B5fFC21B6dBf175597F091',
//     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
//     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
//     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
//   },
//   quoteTokenCoinGeckoId: 'matic-network',
//   harvest: true,
//   tokenPerBlock: '1',
//   sortOrder: 0,
//   isFinished: false,
//   tokenDecimals: 18,
//   isVault: false
// },
// {
//   sousId: 2,
//   tokenName: 'CHLI-USDT',
//   tokenAdressInLp: '0xBEd0e70eE8488ab8828705fFADff66FBC147973B',
//   tokenAddress: '0xDf94B2562331cE0e469f66d517c29c1F5751399D',
//   stakingTokenName: QuoteToken.CHLI,
//   quoteTokenSymbol: QuoteToken.USDT,
//   stakingTokenAddress: '0x2E9AEA1dfAE63ca06deB3872fCcFc701D9df657F',
//   contractAddress: {
//     97: '',
//     56: '',
//     80001: '0x1756Bb68218EFa2Af36D0931bA747f252fC480D2',
//     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
//     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
//     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
//   },
//   quoteTokenCoinGeckoId: 'tether',
//   harvest: true,
//   tokenPerBlock: '1',
//   sortOrder: 0,
//   isFinished: false,
//   tokenDecimals: 18,
//   isVault: false
// },
// ]
export default farms
