// @ts-nocheck
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useState, useCallback, useEffect } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Web3 from 'web3'
// @ts-ignore
import { useTransactionAdder } from '../../state/transactions/hooks'
import { getAddress } from 'utils/addressHelpers'
import { useStakingContract } from '../../hooks/useContract'
import { RouteComponentProps } from 'react-router-dom'
// import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'
import QuestionHelper from '../../components/QuestionHelper'
import { RowBetween } from '../../components/Row'
// @ts-ignore
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
// @ts-ignore
import { ButtonPrimary, ButtonEmpty } from '../../components/Button'
import StakingModalNew from '../../components/earn/StakingModalNew'
// import { useStakingInfo } from '../../state/stake/hooks'
import UnstakingModal from '../../components/earn/UnstakingModaNew'
import VestingModal from '../../components/earn/VestingModal'
import ClaimRewardModal from '../../components/earn/ClaimRewardModalNew'
// import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
// import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'
import { usePools } from 'state/hooks'
// import { wrappedCurrency } from '../../utils/wrappedCurrency'
// import { currencyId } from '../../utils/currencyId'
// import { useTotalSupply } from '../../data/TotalSupply'
// import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
// import useUSDCPrice from '../../utils/useUSDCPrice'
// import { BIG_INT_ZERO } from '../../constants'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`
const ImgContainer = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  box-shadow: rgb(0 0 0 / 8%) 0px 6px 10px;
  background-color: rgb(255, 255, 255);
`
const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${showBackground ? theme.black : theme.bg5} 100%) `};
`

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 0.4 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`
const VestingMode = styled.div`
  border-radius: 12px;
  border: none;
  background: rgb(64, 68, 79);
  display: flex;
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 0px;
  margin-left: 8px;
  cursor: pointer;
`
const ButtonContainer = styled.div`
  padding: 0.35rem 0.6rem;
  border-radius: 12px;
  background: rgb(33, 114, 229);
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 500;
`
const VestingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export default function Manage({
  match: {
    params: { contract }
  }
}: RouteComponentProps<{ contract: string }>) {
  const { account, chainId } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const timestamp = Math.floor(parseFloat(+new Date()) / 1000)
  const web3 = new Web3(window.ethereum)
  const pools = usePools(account)
  console.log(contract)
  let poolInfo = null
  if (pools && pools.length > 0) {
    poolInfo = pools.find(p => p.sousId == contract)
    console.log(pools)
  }
  const [isVestingEnabled, toggleVesting] = useState(poolInfo && poolInfo.vestingInfo ? poolInfo.vestingInfo : false)

  useEffect(() => {
    if (
      poolInfo &&
      poolInfo.userData &&
      poolInfo.userData.vestingInfo &&
      isVestingEnabled !== poolInfo.userData.vestingInfo
    ) {
      toggleVesting(poolInfo.userData.vestingInfo)
    }
  }, [isVestingEnabled, poolInfo, poolInfo.vestingInfo])
  // @ts-ignore
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)
  const [showStakingModal, setShowStakingModal] = useState(false)
  // @ts-ignore
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showVestingModal, setShowVestingModal] = useState(false)
  const showAddLiquidityButton = false
  const disableTop = true
  let valueOfTotalStakedAmountInWETH
  if (poolInfo.totalDeposited) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = web3.utils.fromWei(poolInfo.totalDeposited, 'ether')
  }
  let depositedLiquired
  if (poolInfo.userData?.stakingTokenBalance) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    depositedLiquired = web3.utils.fromWei(poolInfo.userData?.stakedBalance, 'ether')
  }
 
  let countUpAmount =
    poolInfo.userData && poolInfo.userData.earnedInfo
      ? // ? web3.utils.fromWei(poolInfo?.userData?.stakedBalance, 'ether')
        parseFloat(poolInfo?.userData?.earnedInfo.toString()) / 1e18
      : '0'
  if (isVestingEnabled && poolInfo.userData.stakedBalance && poolInfo.userData.earnedInfo) {
    const totalEarned = parseFloat(poolInfo?.userData?.stakedBalance.toString()) / 1e18
    countUpAmount = parseFloat(poolInfo?.userData?.stakedBalance.toString()) / 1e18 - totalEarned / 2
  }
  const userLiquidityUnstaked =
    poolInfo.userData && poolInfo.userData.stakedBalance
      ? parseFloat(poolInfo?.userData?.stakedBalance.toString()) / 1e18
      : '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'
  const toggleWalletModal = useWalletModalToggle()
  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])
  
  return (
    <PageWrapper gap="lg" justify="center">
      {poolInfo.tokens && (
        <StakingModalNew isOpen={showStakingModal} onDismiss={() => setShowStakingModal(false)} poolInfo={poolInfo} />
      )}
      <UnstakingModal isOpen={showUnstakingModal} onDismiss={() => setShowUnstakingModal(false)} poolInfo={poolInfo} />
      <ClaimRewardModal
        isOpen={showClaimRewardModal}
        onDismiss={() => setShowClaimRewardModal(false)}
        poolInfo={poolInfo}
      />
      <VestingModal
        isOpen={showVestingModal}
        onDismiss={() => setShowVestingModal(false)}
        poolInfo={poolInfo}
        toggleVestingstate={resp => toggleVesting(resp)}
      />
      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>{poolInfo.tokenName} Liquidity Mining</TYPE.mediumHeader>
        {console.log(poolInfo?.isTokenOnly)}
        {
          !poolInfo?.isTokenOnly ? ( <div style={{ display: 'flex', alignItems: 'center' }}>
          <ImgContainer src={`/images/tokens/${poolInfo.stakingTokenName}.png`} alt={poolInfo.stakingTokenName} />
          <ImgContainer src={`/images/tokens/${poolInfo.quoteTokenSymbol}.png`} alt={poolInfo.quoteTokenSymbol} />
        </div>):
        (
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <ImgContainer src={`/images/tokens/MATIC.png`} alt={poolInfo.stakingTokenName} />
        </div>
        )
        }
       
        {/* {poolInfo.stakingTokenName !== "WORLDOFDEFI"  ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ImgContainer src={`/images/tokens/${stakingTokenName}.png`} alt={stakingTokenName} />
              <ImgContainer src={`/images/tokens/${quoteTokenSymbol}.png`} alt={quoteTokenSymbol} />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ImgContainer src={`/images/tokens/MATIC.png`} alt={stakingTokenName} />
            </div>
          )} */}
      </RowBetween>
      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
            <TYPE.body fontSize={24} fontWeight={500}>
              {valueOfTotalStakedAmountInWETH && `${valueOfTotalStakedAmountInWETH.toString()} WorldOfDefi LP`}
            </TYPE.body>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <TYPE.body style={{ margin: 0 }}> Pool Rate / DAY</TYPE.body>
            <TYPE.body fontSize={24} fontWeight={500}>
              {`${poolInfo.rewardPerDay} WorldOfDefi / day`}
            </TYPE.body>
            {/* <TYPE.body fontSize={24} fontWeight={500}>
              {stakingInfo?.totalRewardRate2
                ?.multiply((60 * 60 * 24 * 7).toString())
                ?.toFixed(5, { groupSeparator: ',' }) ?? ' - '}
              &nbsp;{currencyB?.symbol}
              {'   / week'}
            </TYPE.body> */}
          </AutoColumn>
        </PoolData>
      </DataRow>
      <VestingContainer>
        {/* Your reward will get vested! */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <div>
            Vesting Mode
            <QuestionHelper text="This button will toggle the vesting nature of rewards. The final value of toggled selection will determine whether the rewards will be lineraly vested or immediately claimed (after 50% burn)" />
          </div> */}
       
        </div>
      </VestingContainer>
      {showAddLiquidityButton && (
        <VoteCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <TYPE.white fontWeight={600}>Step 1. Get Moxie-LP Liquidity tokens</TYPE.white>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <TYPE.white fontSize={14}>
                  {`Moxie-LP tokens are required. Once you've added liquidity to the ${poolInfo.quoteTokenSymbol}-${poolInfo.stakingTokenName} pool you can stake your liquidity tokens on this page.`}
                </TYPE.white>
              </RowBetween>
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width={'fit-content'}
                as={Link}
                to={`/add/${poolInfo.stakingTokenAddress}/${poolInfo.tokenAdressInLp}`}
              >
                {`Add ${poolInfo.stakingTokenName}-${poolInfo.quoteTokenSymbol} liquidity`}
              </ButtonPrimary>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </VoteCard>
      )}

      <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={disableTop} bgColor="#2172E5" showBackground={!showAddLiquidityButton}>
            <CardSection>
              <CardBGImage desaturate />
              <CardNoise />
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Your liquidity deposits</TYPE.white>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {depositedLiquired ? parseFloat(depositedLiquired).toFixed(8) : '-'}
                  </TYPE.white>
                  <TYPE.white>
                    {poolInfo.stakingTokenName}-{poolInfo.quoteTokenSymbol}
                  </TYPE.white>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
          <StyledBottomCard dim={poolInfo?.userData?.stakingTokenBalance.toString() === '0'}>
            <CardBGImage desaturate />
            <CardNoise />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <TYPE.black>Your Unclaimed Moxie</TYPE.black>
                </div>
                {poolInfo &&
                  poolInfo?.userData &&
                  poolInfo?.userData?.earnedInfo &&
                  parseFloat(poolInfo?.userData.earnedInfo.toString()) >= 0 && (
                    <ButtonEmpty
                      padding="8px"
                      borderRadius="8px"
                      width="fit-content"
                      onClick={() => setShowClaimRewardModal(true)}
                    >
                      Claim
                    </ButtonEmpty>
                  )}
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {`${poolInfo.rewardPerDay} ${poolInfo.stakingTokenName} / day`}
                </TYPE.black>
              </RowBetween>
              {/* <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount2}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious2)}
                    end={parseFloat(countUpAmount2)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {stakingInfo?.totalRewardRate2
                    ?.multiply((60 * 60 * 24 * 7).toString())
                    ?.toSignificant(4, { groupSeparator: ',' }) ?? '-'}
                  &nbsp; ${currencyB?.symbol}
                  {'  / week'}
                </TYPE.black>
              </RowBetween> */}
            </AutoColumn>
          </StyledBottomCard>
        </BottomSection>
        <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
          {/* <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span> */}
          {/* You can claim 25% of your WorldOfDefi token rewards after the farming program ends, and the remaining will be
          released 25% every other two months! */}
        </TYPE.main>
        {poolInfo && (
          <DataRow style={{ marginBottom: '1rem' }}>
            <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleDepositClick}>
          
                Deposit
            </ButtonPrimary>

            {poolInfo.userData?.stakedBalance && parseFloat(poolInfo.userData?.stakedBalance.toString()) > 0 && (
              <>
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width="160px"
                  onClick={() => setShowUnstakingModal(true)}
                >
                  Withdraw
                </ButtonPrimary>
              </>
            )}
          </DataRow>
        )}
     
          
      </PositionInfo>
      {/* {!userLiquidityUnstaked ? null : parseFloat(userLiquidityUnstaked) === 0 ? null : (
        <TYPE.main>{userLiquidityUnstaked} WorldOfDefi-LP tokens available</TYPE.main>
      )} */}
    </PageWrapper>
  )
}
