// @ts-ignore
import { PoolConfig, QuoteToken } from './types'

const farms: PoolConfig[] = [
  // {
  //   sousId: 1,
  //   tokenName: 'CHLI',
  //   tokenAdressInLp: '0xdf94b2562331ce0e469f66d517c29c1f5751399d',
  //   tokenAddress: '0xdf94b2562331ce0e469f66d517c29c1f5751399d',
  //   stakingTokenName: QuoteToken.CHLI,
  //   quoteTokenSymbol: QuoteToken.CHLI,
  //   stakingTokenAddress: '0xdf94b2562331ce0e469f66d517c29c1f5751399d',
  //   contractAddress: {
  //     97: '',
  //     56: '',
  //     80001: '0xa29f34762D7718A65bDB4D5135625Cbb0eEA6497',
  //     5: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     1: '0x01b21aFC1415F1Df68d034351699B5c639CF160A',
  //     137: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF' // wrong
  //   },
  //   quoteTokenCoinGeckoId: 'tether',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 0,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   isVault: true
  // }
]
export default farms
