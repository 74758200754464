/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit'
import pools from 'constants/config/farms'
import vaults from 'constants/config/vaults'
import { fetchPoolsLpData } from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserPendingRewards,
  fetchUserVestingInfo,
  fetchVaultUserDepositInfo,
  fetchUserEarnedInfo
  // fetchPoolUserHarvestInterval,
  // fetchPoolUserCanHarvestPendingReward
} from './fetchPoolsUser'
import { PoolsState, Pool } from '../types'

const poolsConfig = pools.concat(vaults)
const initialState: PoolsState = { data: [...poolsConfig] }

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload
      state.data = state.data.map(pool => {
        const livePoolData = livePoolsData.find(entry => entry.sousId === pool.sousId)
        return { ...pool, ...livePoolData }
      })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map(pool => {
        const userPoolData = userData.find((entry: { sousId: number }) => entry.sousId === pool.sousId)
        return { ...pool, userData: userPoolData }
      })
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, sousId } = action.payload
      const index = state.data.findIndex(p => p.sousId === sousId)
      state.data[index] = {
        ...state.data[index],
        // @ts-ignore
        userData: { ...state.data[index].userData, [field]: value }
      }
    }
  }
})

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } = PoolsSlice.actions

// Thunks
export const fetchPoolsPublicDataAsync = () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
  // const blockLimits = await fetchPoolsBlockLimits()
  // const totalStakings = await fetchPoolsTotalStatking()
  // console.log('total staking is', totalStakings)
  const poolImagesResp = await fetch('https://raw.githubusercontent.com/Parv250/list/main/chilliListtt.json')
  const poolImages = await poolImagesResp.json()
  const PoolLpDatas = await fetchPoolsLpData()
  const liveData = poolsConfig.map(pool => {
    let stakingTokenImg = ''
    let quoteTokenImg = ''
    if (poolImages && poolImages.tokens && poolImages.tokens.length > 0) {
      const stakingToken = poolImages.tokens.find(
        (eachToken: { symbol: string | undefined }) => eachToken.symbol === pool.stakingTokenName
      )
      if (stakingToken && stakingToken.logoURI) {
        stakingTokenImg = stakingToken.logoURI
      }
      const quoteToken = poolImages.tokens.find(
        (eachToken: { symbol: string | undefined }) => eachToken.symbol === pool.quoteTokenSymbol
      )
      if (quoteToken && quoteToken.logoURI) {
        quoteTokenImg = quoteToken.logoURI
      }
    }
    // const blockLimit = blockLimits.find(entry => entry.sousId === pool.sousId)
    // const totalStaking = totalStakings.find(entry => entry.sousId === pool.sousId)
    const PoolLpData = PoolLpDatas.find(
      // @ts-ignore
      entry => entry.sousId === pool.sousId
    )
    return {
      stakingTokenImg,
      quoteTokenImg,
      // ...blockLimit,
      // ...totalStaking,
      // @ts-ignore
      ...PoolLpData
    }
  })

  dispatch(setPoolsPublicData(liveData))
}

export const fetchPoolsUserDataAsync = (account: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  try {
    const allowances = await fetchPoolsAllowance(account)

    const stakingTokenBalances = await fetchUserBalances(account)

    const stakedBalances = await fetchUserStakeBalances(account)

    const earnedInfo = await fetchUserEarnedInfo(account)
    console.log(earnedInfo)
    // console.log({ earnedInfo })
    // const pendingRewards = await fetchUserPendingRewards(account)
    // const userCanHarvest = await fetchPoolUserCanHarvestPendingReward(account)
    // const harvestInterval = await fetchPoolUserHarvestInterval(account)

    const userData = poolsConfig.map(pool => ({
      sousId: pool.sousId,
      allowance: allowances[pool.sousId],
      stakingTokenBalance: stakingTokenBalances[pool.sousId],
      stakedBalance: stakedBalances[pool.sousId],
      vestingInfo: 0,
      earnedInfo: earnedInfo[pool.sousId],
      vaultDepositInfo: 0
      // pendingReward: pendingRewards[pool.sousId],
      // canHarvest: userCanHarvest[pool.sousId],
      // harvestInterval: harvestInterval[pool.sousId]
    }))

    dispatch(setPoolsUserData(userData))
  } catch (error) {
    console.error('error is', error)
  }
}

export const updateUserAllowance = (sousId: string, account: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  const allowances = await fetchPoolsAllowance(account)
  dispatch(
    updatePoolsUserData({
      sousId,
      field: 'allowance',
      value: allowances[sousId]
    })
  )
}

export const updateUserBalance = (sousId: string, account: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  const tokenBalances = await fetchUserBalances(account)
  dispatch(
    updatePoolsUserData({
      sousId,
      field: 'stakingTokenBalance',
      value: tokenBalances[sousId]
    })
  )
}

export const updateUserStakedBalance = (sousId: string, account: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  const stakedBalances = await fetchUserStakeBalances(account)
  dispatch(
    updatePoolsUserData({
      sousId,
      field: 'stakedBalance',
      value: stakedBalances[sousId]
    })
  )
}

export const updateUserPendingReward = (sousId: string, account: string) => async (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  const pendingRewards = await fetchUserPendingRewards(account)
  dispatch(
    updatePoolsUserData({
      sousId,
      field: 'pendingReward',
      value: pendingRewards[sousId]
    })
  )
}

export default PoolsSlice.reducer
