export default {
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    80001: '0x27A9fca03eD753f0729E3A86a6123d8C5c90b8e2',
    5: '0x27A9fca03eD753f0729E3A86a6123d8C5c90b8e2',
    137: '0x429876c4a6f89FB470E92456B8313879DF98B63c',
    1: '0x429876c4a6f89FB470E92456B8313879DF98B63c'
  },
  wbnb: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    80001: '0x86652c1301843B4E06fBfbBDaA6849266fb2b5e7',
    5: '0x86652c1301843B4E06fBfbBDaA6849266fb2b5e7',
    137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    1: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'
  }
}
