// @ts-nocheck
import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import Web3 from 'web3'
import styled from 'styled-components'
import { getAddress } from 'utils/addressHelpers'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { Pool } from '../../state/types'
import { useVaultContract } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useActiveWeb3React } from '../../hooks'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  poolInfo: Pool
}

export default function UnstakingModalVault({ isOpen, onDismiss, poolInfo }: StakingModalProps) {
  const { account } = useActiveWeb3React()
  const web3 = new Web3(window.ethereum)
  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)
  let depositedLiquidity
  if (poolInfo?.userData?.vaultDepositInfo) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    depositedLiquidity = web3.utils.fromWei(poolInfo?.userData?.vaultDepositInfo, 'ether')
  }
  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingContract = useVaultContract(getAddress(poolInfo.contractAddress))

  async function onWithdraw() {
    if (stakingContract && poolInfo?.userData?.vaultDepositInfo) {
      setAttempting(true)
      await stakingContract
        .unLockStakingToken({ gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!poolInfo?.userData?.vaultDepositInfo) {
    error = error ?? 'Enter an amount'
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOndismiss} />
          </RowBetween>
          {depositedLiquidity && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {/* {<FormattedCurrencyAmount currencyAmount={poolInfo?.userData?.vaultDepositInfo} />} */}
                {depositedLiquidity}
              </TYPE.body>
              <TYPE.body>Deposited liquidity:</TYPE.body>
            </AutoColumn>
          )}

          {/* // sanchit */}
          {/* {vaultInfo?.earnedAmount && (
            <AutoColumn justify="center" gap="md">
              <TYPE.body fontWeight={600} fontSize={36}>
                {<FormattedCurrencyAmount currencyAmount={vaultInfo?.earnedAmount} />}
              </TYPE.body>
              <TYPE.body>Unclaimed Chilli</TYPE.body>
            </AutoColumn>
          )} */}
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you withdraw, your CHLI is claimed and your liquidity is removed from the mining pool.
          </TYPE.subHeader>
          <ButtonError disabled={!!error} error={!!error && !!depositedLiquidity} onClick={onWithdraw}>
            {error ?? 'Withdraw & Claim'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.body fontSize={20}>Withdrawing {depositedLiquidity} CHLI </TYPE.body>
            {/* // sanchit */}
            {/* <TYPE.body fontSize={20}>Claiming {vaultInfo?.earnedAmount?.toSignificant(4)} Chilli</TYPE.body> */}
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            <TYPE.body fontSize={20}>Withdrew CHLI </TYPE.body>
            <TYPE.body fontSize={20}>Claimed Chilli</TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
