export enum QuoteToken {
  'BNB' = 'BNB',
  'CAKE' = 'CAKE',
  'SYRUP' = 'SYRUP',
  'BUSD' = 'BUSD',
  'TWT' = 'TWT',
  'UST' = 'UST',
  'ETH' = 'ETH',
  'COMP' = 'COMP',
  'SUSHI' = 'SUSHI',
  'TPT' = 'TPT',
  'CNT' = 'CNT',
  'MUSD' = 'MUSD',
  'MATIC' = 'MATIC',
  'PR' = 'PR',
  'TEST1' = 'TEST1',
  'LP' = 'LP',
  'BTC' = 'BTC',
  'MAHA' = 'MAHA',
  'PEAR' = 'PEAR',
  'WMATIC' = 'WMATIC',
  'USDC' = 'USDC',
  'DAI' = 'DAI',
  'USDT' = 'USDT',
  'LUSD' = 'LUSD',
  'LUSDT' = 'LUSDT',
  'LARTH' = 'LARTH',
  'CHLI' = 'CHLI',
  'WORLDOFDEFI' = 'WORLDOFDEFI'
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance' // Pools using native BNB behave differently than pools using a token
}

export interface Address {
  97?: string
  56: string
  5?: string
  80001: string
  137: string
  1?: string
}

export interface FarmConfig {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  tokenSymbol: string
  tokenAddresses: Address
  quoteTokenSymbol: QuoteToken
  quoteTokenAdresses: Address
  multiplier?: string
  poolHarvestInterval?: number
  isCommunity?: boolean
  singleSidedToken?: Address
  singleSidedToToken?: Address
  singleSidedTokenName: string
  singleSidedToTokenName: string
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
}

export interface PoolConfig {
  sousId: number
  image?: string
  TopImage?: string
  tokenName: string
  tokenAdressInLp: string
  tokenAddressSecondInLp?: string
  tokenAddress: string
  stakingTokenName: QuoteToken
  quoteTokenSymbol: QuoteToken
  stakingLimit?: number
  stakingTokenAddress?: string
  stakingTokenDecimals?: number
  contractAddress: Address
  tokenPerBlock: string
  sortOrder?: number
  multiReward?: string[]
  coinGeckoIds?: string[]
  stakingTokenCoinGeckoid?: string
  rewardTokenCoinGeckoid?: string
  multiRewardTokenPerBlock?: string[]
  harvest?: boolean
  isFinished?: boolean
  tokenDecimals: number
  metamaskImg?: string
  isVault?: boolean
  isTokenOnly?: boolean
  quoteTokenCoinGeckoId?: string
}
