/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-nocheck
import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
// import { STAKING_REWARDS_INFO, VAULT_REWARDS_INFO } from '../../state/stake/hooks'
import { TYPE, ExternalLink } from '../../theme'
import PoolCard from '../../components/earn/PoolCardNew'
import { RowBetween } from '../../components/Row'
// import { useVaultInfo } from '../../state/stake/hooks'
import { usePools } from '../../state/hooks'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
//@ts-ignore
import { Countdown } from './Countdown'
// import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { useLocation } from 'react-router-dom'
// import VaultCard from 'components/earn/VaultCard'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

export default function Earn() {
  const { account } = useActiveWeb3React()
  // const stakingInfos = useStakingInfo()
  // const vaultInfos = useVaultInfo()
  // @ts-ignore
  const pools = usePools(account)
  console.log('pools are', { pools })
  const DataRow = styled(RowBetween)`
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
  `

  const location = useLocation()
  const vaults = []
  const poolsData = []
  if (pools && pools.length > 0) {
    pools.forEach(eachPool => {
      if (eachPool.isVault) {
        vaults.push(eachPool)
      } else {
        poolsData.push(eachPool)
      }
    })
  }
  // const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)
  // const vaultRewardsExist = Boolean(typeof chainId === 'number' && (VAULT_REWARDS_INFO[chainId]?.length ?? 0) > 0)
  console.log(location.pathname, { vaults }, { poolsData })
  return (
    <PageWrapper gap="lg" justify="center">
      {location.pathname === '/worldofdefi' ? (
        <div>
          <TopSection gap="md">
            <DataCard>
              <CardBGImage />
              <CardNoise />
              <CardSection>
                <AutoColumn gap="md">
                  <RowBetween>
                    <TYPE.white fontWeight={600}>World Of Defi liquidity mining</TYPE.white>
                  </RowBetween>
                  <RowBetween>
                    <TYPE.white fontSize={14}>
                      Deposit your Liquidity Provider tokens to receive REWARDS, the World Of Defi protocol governance
                      token.
                    </TYPE.white>
                  </RowBetween>{' '}
                  <ExternalLink style={{ color: 'white', textDecoration: 'underline' }} href="#" target="_blank">
                    {/* <TYPE.white fontSize={14}>Read more about CHILLI</TYPE.white> */}
                  </ExternalLink>
                </AutoColumn>
              </CardSection>
              <CardBGImage />
              <CardNoise />
            </DataCard>
          </TopSection>
          <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
            <DataRow style={{ alignItems: 'baseline' }}>
              <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Participating pools</TYPE.mediumHeader>
            </DataRow>
          </AutoColumn>
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <TopSection gap="md">
            <DataCard>
              <CardBGImage />
              <CardNoise />
              <CardSection>
                <AutoColumn gap="md">
                  <RowBetween>
                    <TYPE.white fontWeight={600}>Single Asset Vaults</TYPE.white>
                  </RowBetween>
                  <RowBetween>
                    <TYPE.white fontSize={14}>
                      Lock Dai/Moxie Swap for a period of time and earn a fixed number of Dai/worldofdefi tokens.
                    </TYPE.white>
                  </RowBetween>{' '}
                  <ExternalLink style={{ color: 'white', textDecoration: 'underline' }} href="#" target="_blank">
                    {/* <TYPE.white fontSize={14}>Read more about CHILLI</TYPE.white> */}
                  </ExternalLink>
                </AutoColumn>
              </CardSection>
              <CardBGImage />
              <CardNoise />
            </DataCard>
          </TopSection>
          <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
            <DataRow style={{ alignItems: 'baseline' }}>
              <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>Participating Vaults</TYPE.mediumHeader>
            </DataRow>
          </AutoColumn>
        </div>
      )}

      <PoolSection>
        {location.pathname === '/worldofdefi' &&
          poolsData?.length > 0 &&
          poolsData.map(eachPool => {
            return <PoolCard key={eachPool.stakingRewardAddress} pool={eachPool} />
          })}
        {location.pathname === '/single-asset' &&
          vaults?.length > 0 &&
          vaults.map(eachPool => {
            return <PoolCard key={eachPool.stakingRewardAddress} pool={eachPool} />
          })}
        {/* {location.pathname === '/uni' && stakingRewardsExist && stakingInfos?.length === 0 ? (
          <Loader style={{ margin: 'auto' }} />
        ) : !stakingRewardsExist ? (
          'No active rewards'
        ) : (
          stakingInfos?.map(stakingInfo => {
            // need to sort by added liquidity here
            return (
              <>
                {location.pathname === '/uni' && (
                  <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
                )}
              </>
            )
          })
        )} */}

        {/* {location.pathname === '/single-asset' && vaultRewardsExist && vaultInfos?.length === 0 ? (
          <Loader style={{ margin: 'auto' }} />
        ) : !vaultRewardsExist ? (
          'No active rewards'
        ) : (
          vaultInfos?.map(vaultInfo => {
            // need to sort by added liquidity here
            return (
              <>
                {location.pathname === '/single-asset' && (
                  <VaultCard key={vaultInfo.vaultRewardAddress} vaultInfo={vaultInfo} />
                )}
              </>
            )
          })
        )} */}
      </PoolSection>
    </PageWrapper>
  )
}
