import { Interface } from '@ethersproject/abi'
import { abi as STAKING_REWARDS_ABI } from './Staking_reward_Abi.json'
import { abi as STAKING_REWARDS_FACTORY_ABI } from './staking_reward_Factory.json'
import { abi as VAULT_REWARDS_ABI } from './Vault_reward_Abi.json'

const STAKING_REWARDS_INTERFACE = new Interface(STAKING_REWARDS_ABI)

const STAKING_REWARDS_FACTORY_INTERFACE = new Interface(STAKING_REWARDS_FACTORY_ABI)

const VAULT_REWARDS_INTERFACE = new Interface(VAULT_REWARDS_ABI)

export { STAKING_REWARDS_FACTORY_INTERFACE, STAKING_REWARDS_INTERFACE, VAULT_REWARDS_INTERFACE }
