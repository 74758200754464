/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React, { useState, useEffect, useMemo } from 'react'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import styled from 'styled-components'
import Web3 from 'web3'
import { TYPE, StyledInternalLink } from '../../theme'
// import DoubleCurrencyLogo from '../DoubleLogo'
import { ButtonPrimary } from '../Button'
import { Pool } from '../../state/types'
import { Break, CardNoise, CardBGImage } from './styled'
import { Countdown } from '../../pages/Earn/Countdown'
import { useStakingInfo } from '../../state/stake/hooks'
import { getAddress } from 'utils/addressHelpers'
import { fetchPrice } from 'state/hooks'
import BigNumber from 'bignumber.js'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${showBackground ? theme.black : theme.bg5} 100%) `};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`
const ImgContainer = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  box-shadow: rgb(0 0 0 / 8%) 0px 6px 10px;
  background-color: rgb(255, 255, 255);
`
// const APR = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `

// const BottomSection = styled.div<{ showBackground: boolean }>`
//   padding: 12px 16px;
//   opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
//   border-radius: 0 0 12px 12px;
//   display: flex;
//   flex-direction: row;
//   align-items: baseline;
//   justify-content: space-between;
//   z-index: 1;
// `

export default function PoolCard({ pool }: { pool: Pool }) {
  const {
    stakingTokenName,
    tokenName,
    contractAddress,
    userData,
    quoteTokenSymbol,
    totalDeposited,
    stakingTokenImg,
    quoteTokenImg,
    rewardPerDay,
    isVault,
    vaultApy,
    vaultLimit,
    vaultTotalDeposited,
    availableLimit,
    vaultLaunchPad,
    vaultStakingTime,
    lpTotalInQuoteToken,
    sousId,
    isTokenOnly
  } = pool

  const [QuoteTokenPrice, setQuoteTokenPrice] = useState(new BigNumber(1))
  const [CHilliPrice, setCHilliPrice] = useState(new BigNumber(1))
  useEffect(() => {
    const pricefunc = async () => {
      const quotetokenprice = await fetchPrice(pool.quoteTokenCoinGeckoId)
      // const chilliprice = await

      if (quotetokenprice) {
        setQuoteTokenPrice(quotetokenprice)
      }
    }
    pricefunc()
  }, [pool])

  const totalValue: BigNumber = useMemo(() => {
    if (!lpTotalInQuoteToken) {
      return null
    }

    return QuoteTokenPrice ? new BigNumber(QuoteTokenPrice).times(lpTotalInQuoteToken) : 0
  }, [QuoteTokenPrice, lpTotalInQuoteToken])

  const apy =
    rewardPerDay && CHilliPrice && totalValue
      ? new BigNumber(rewardPerDay)
          .multipliedBy(new BigNumber(365).multipliedBy(new BigNumber(CHilliPrice)))
          .dividedBy(new BigNumber(totalValue))
      : Number(70)
  const web3 = new Web3(window.ethereum)
  // @ts-ignore
  const isStaking = Boolean(parseFloat(userData?.stakingTokenBalance) > 0)
  // let returnOverMonth: Percent = new Percent('0')
  let valueOfTotalStakedAmountInWETH
  if (totalDeposited) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = web3.utils.fromWei(totalDeposited, 'ether')
  }
  return (
    <Wrapper showBackground={isStaking} bgColor="#2172E5">
      <CardBGImage desaturate />
      <CardNoise />

      <TopSection>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isTokenOnly ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ImgContainer src={`/images/tokens/${stakingTokenName}.png`} alt={stakingTokenName} />
              <ImgContainer src={`/images/tokens/${quoteTokenSymbol}.png`} alt={quoteTokenSymbol} />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ImgContainer src={`/images/tokens/MATIC.png`} alt={stakingTokenName} />
            </div>
          )}
          <TYPE.white fontWeight={600} fontSize={24} style={{ marginLeft: '8px' }}>
            {tokenName}
          </TYPE.white>
        </div>
        <StyledInternalLink
          to={isVault ? `/single-asset/${getAddress(contractAddress)}` : `/worldofdefi/${sousId}`}
          style={{ width: '100%', maxWidth: '200px' }}
        >
          <ButtonPrimary padding="8px" borderRadius="8px">
            {isStaking ? 'Deposit' : 'Deposit'}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      {isVault ? (
        <StatContainer>
          <RowBetween>
            {/* <TYPE.white> Launch Date </TYPE.white> */}
            {/* <TYPE.white>{new Date(parseFloat(vaultLaunchPad) * 1000).toDateString()}</TYPE.white> */}
            <Countdown exactEnd={new Date(parseFloat(vaultLaunchPad) * 1000)} />
            {/* { vaultLaunchPad && vaultLaunchPad > 0 && <Countdown exactEnd={new Date(parseFloat(vaultLaunchPad) * 1000).toDateString()} />}  */}
          </RowBetween>

          <RowBetween>
            <TYPE.white> Total Deposited </TYPE.white>
            <TYPE.white>{vaultTotalDeposited && vaultTotalDeposited.toString()}</TYPE.white>
          </RowBetween>

          <RowBetween>
            <TYPE.white> Vault Limit </TYPE.white>
            <TYPE.white>{vaultLimit}</TYPE.white>
          </RowBetween>
          <RowBetween>
            <TYPE.white> Available Limit </TYPE.white>
            <TYPE.white>{availableLimit}</TYPE.white>
          </RowBetween>
          <RowBetween>
            <TYPE.white> Maturity Period </TYPE.white>
            <TYPE.white>{vaultStakingTime} days</TYPE.white>
          </RowBetween>
          <RowBetween>
            <TYPE.white> APR </TYPE.white>
            <TYPE.white>{vaultApy} %</TYPE.white>
          </RowBetween>
        </StatContainer>
      ) : (
        <StatContainer>
          <RowBetween>
            {/* // timeset */}
            <Countdown exactEnd={new Date(1635576806 * 1000)} />
          </RowBetween>
          <RowBetween>
            <TYPE.white> Total deposited</TYPE.white>
            <TYPE.white>
              {valueOfTotalStakedAmountInWETH && `${parseFloat(valueOfTotalStakedAmountInWETH.toString()).toFixed(2)} `}{' '}
              {tokenName}
            </TYPE.white>
          </RowBetween>
          <RowBetween>
            <TYPE.white> {stakingTokenName} Pool rate </TYPE.white>
            <TYPE.white>{`${rewardPerDay} ${stakingTokenName} / day`}</TYPE.white>
          </RowBetween>
          {/* <RowBetween>
          <TYPE.white> {quoteTokenSymbol} Pool rate </TYPE.white>
          <TYPE.white>{`${stakingInfo.totalRewardRate2
            ?.multiply(`${60 * 60 * 24 * 7}`)
            ?.toFixed(5, { groupSeparator: ',' })} ${quoteTokenSymbol} / week`}</TYPE.white>
        </RowBetween> */}
          <RowBetween>
            <TYPE.white> Total Value </TYPE.white>
            <TYPE.white>{isTokenOnly ? `5672` : totalValue ? totalValue.toNumber().toFixed(2) : 0} $</TYPE.white>
          </RowBetween>
          <RowBetween>
            <TYPE.white> APR% </TYPE.white>
            <TYPE.white>
    
            {isTokenOnly ? `395` : parseFloat(apy?.toString()) > 0 && parseFloat(apy?.toString())?.toFixed(2)} %
            </TYPE.white>
          </RowBetween>
        </StatContainer>
      )}

      {isStaking && (
        <>
          <Break />
          {/* <BottomSection showBackground={true}>
            <TYPE.black color={'white'} fontWeight={500}>
              <span>Your rate</span>
            </TYPE.black>

            <TYPE.black style={{ textAlign: 'right' }} color={'white'} fontWeight={500}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span>
              {`${stakingInfo.rewardRate
                ?.multiply(`${60 * 60 * 24 * 7}`)
                ?.toSignificant(4, { groupSeparator: ',' })} UNI / week`}
            </TYPE.black>
          </BottomSection> */}
        </>
      )}
    </Wrapper>
  )
}
