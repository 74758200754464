/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-ignore
// @ts-nocheck
import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, WETH, Pair } from '@uniswap/sdk'
import { useMemo } from 'react'
// @ts-ignore
import { DAI, UNI, USDC, USDT, WBTC, Chilli, TEST1, TEST2 } from '../../constants'
import { STAKING_REWARDS_INTERFACE, VAULT_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'

export const STAKING_GENESIS = 1628571600

export const REWARDS_DURATION_DAYS = 60

export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
  }[]
} = {
  [ChainId.MATIC_TESTNET]: [
    // {
    //   tokens: [WETH[ChainId.MATIC_TESTNET], Chilli],
    //   stakingRewardAddress: '0x81Fd61DB8eF18f762e846AF6271EeA8B32A0De14'
    // },
    // {
    //   tokens: [WETH[ChainId.MATIC_TESTNET], DAI],
    //   stakingRewardAddress: '0x728E2B01733f90a88e2096E2a8216039416f85d4'
    // },
    // {
    //   tokens: [Chilli, DAI],
    //   stakingRewardAddress: '0xEE48f1DbB66226c6495fFD0a244afB98307e5C66'
    // }
  ]
}
export const VAULT_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token]
    vaultRewardAddress: string
  }[]
} = {
  [ChainId.MATIC_TESTNET]: [
    {
      tokens: [DAI],
      vaultRewardAddress: '0x1213842471Df836F2f36e7dbA74D378Cbc0C45b3'
    },
    {
      tokens: [Chilli],
      vaultRewardAddress: '0xAd085d4a66B991f6567481a57A499Da96C51dD47'
    }
  ]
}
export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount

  earnedAmount2: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount

  totalRewardRate2: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount,
    totalRewardRate2: TokenAmount
  ) => TokenAmount
}
export interface VaultInfo {
  // the address of the reward contract
  vaultRewardAddress: string

  earnedAmount: TokenAmount

  token: Token
  // the tokens involved in this pair
  totalDeposited: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  maturityPeriod: number
  // user vault amount
  // userVaultAmount: any
  //day of vault launched
  launchDate: number
  // vault's total limit
  vaultLimit: TokenAmount
  //
  stakedAmount: TokenAmount
}
// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const uni = chainId ? UNI[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'bothTokensEarned',
    accountArg
  )
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'bothTokensRewardRate',
    undefined,
    NEVER_RELOAD
  )

  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )
  return useMemo(() => {
    if (!chainId || !uni) return []

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account

      const balanceState = balances[index]

      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]
      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))

        const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState.result?.[0]))
        // @ts-ignore
        const totalRewardRate = new TokenAmount(uni, JSBI.BigInt(rewardRateState.result?.[1]?.[0]))
        // @ts-ignore
        const totalRewardRate2 = new TokenAmount(uni, JSBI.BigInt(rewardRateState.result?.[1]?.[1]))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount,
          totalRewardRate2: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            uni,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }
        const individualRewardRate = getHypotheticalRewardRate(
          stakedAmount,
          totalStakedAmount,
          totalRewardRate,
          totalRewardRate2
        )

        const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber()

        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(uni, JSBI.BigInt(earnedAmountState?.result?.[1]?.[0] ?? 0)),
          earnedAmount2: new TokenAmount(uni, JSBI.BigInt(earnedAmountState?.result?.[1]?.[1] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          totalRewardRate2: totalRewardRate2,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate
        })
      }

      return memo
    }, [])
  }, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, rewardsAddresses, totalSupplies, uni])
}
// gets the staking info from the network for the active chain id

////////////////////////////// vault info /////////////////////////

export function useVaultInfo(vaultAddress?: string | null): VaultInfo[] {
  const { chainId, account } = useActiveWeb3React()
  const info = useMemo(
    () =>
      chainId
        ? VAULT_REWARDS_INFO[chainId]?.filter(vaultRewardInfo =>
            vaultAddress === undefined
              ? true
              : vaultAddress === null
              ? false
              : vaultRewardInfo.vaultRewardAddress === vaultAddress
          ) ?? []
        : [],
    [chainId, vaultAddress]
  )
  // @ts-ignore
  const uni = chainId ? UNI[chainId] : undefined
  const chainIDD = chainId

  const rewardsAddresses = useMemo(() => info.map(({ vaultRewardAddress }) => vaultRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  // @ts-ignore
  const balances = useMultipleContractSingleData(rewardsAddresses, VAULT_REWARDS_INTERFACE, 'balanceOf', accountArg)
  // @ts-ignore
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, VAULT_REWARDS_INTERFACE, 'earned', accountArg)
  // @ts-ignore
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, VAULT_REWARDS_INTERFACE, 'totalSupply')
  // @ts-ignore
  const totalDeposited = useMultipleContractSingleData(rewardsAddresses, VAULT_REWARDS_INTERFACE, 'totalDeposits')
  // @ts-ignore
  // const userInfo = useMultipleContractSingleData(
  //   rewardsAddresses,
  //   VAULT_REWARDS_INTERFACE,
  //   'getUserVaultInfo',
  //   accountArg
  // )

  //   // tokens per second, constants
  const vaultLimit = useMultipleContractSingleData(rewardsAddresses, VAULT_REWARDS_INTERFACE, 'vaultLimit')
  const vestingPeriod = useMultipleContractSingleData(rewardsAddresses, VAULT_REWARDS_INTERFACE, 'vestingPeriod')
  //balances[0],earnedAmounts[1],
  return useMemo(() => {
    if (!chainIDD || !uni) return []

    return rewardsAddresses.reduce<VaultInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      // @ts-ignore
      const totalDepositedState = totalDeposited[index]
      // @ts-ignore
      // const userInfoState = userInfo[index]
      // @ts-ignore
      const vaultLimitState = vaultLimit[index]
      // @ts-ignore
      const vestingPeriodState = vestingPeriod[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        !totalSupplyState?.loading &&
        // totalDepositedState &&
        !totalDepositedState?.loading &&
        // !userInfoState?.loading  &&
        vaultLimitState &&
        !vaultLimitState?.loading &&
        vestingPeriodState &&
        !vestingPeriodState?.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState?.error ||
          totalDepositedState?.error ||
          vaultLimitState?.error ||
          vestingPeriodState?.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const dummyPair = new TokenAmount(tokens[0], '0')

        // console.log(userInfoState.result?.[0], 'sec')

        // check for account, if no account set to 0
        // @ts-ignore
        const stakedAmount = new TokenAmount(dummyPair.token, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        // @ts-ignore
        const earnedAmount = new TokenAmount(dummyPair.token, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0))
        // @ts-ignore
        const totalSupplys = new TokenAmount(dummyPair.token, JSBI.BigInt(totalSupplyState.result?.[0]))
        // @ts-ignore
        const totalDepositeds = new TokenAmount(dummyPair.token, JSBI.BigInt(totalDepositedState.result?.[0]))

        const vaultLimits = new TokenAmount(dummyPair.token, JSBI.BigInt(vaultLimitState.result?.[0]))

        const vestingPeriods = vestingPeriodState.result?.[0]?.toNumber()

        memo.push({
          vaultRewardAddress: rewardsAddress,
          earnedAmount: earnedAmount,
          token: tokens[0],
          totalDeposited: totalDepositeds,
          maturityPeriod: vestingPeriods,
          // userVaultAmount: userInfos,
          launchDate: 1234,
          vaultLimit: vaultLimits,
          stakedAmount: stakedAmount
        })
      }
      // the address of the reward contract

      return memo
    }, [])
  }, [
    balances,
    chainIDD,
    earnedAmounts,
    info,
    // userInfo,
    vaultLimit,
    vestingPeriod,
    totalDeposited,
    rewardsAddresses,
    totalSupplies,
    uni
  ])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined
  const stakingInfos = useStakingInfo()

  return useMemo(() => {
    if (!uni) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(uni, '0')
      ) ?? new TokenAmount(uni, '0')
    )
  }, [stakingInfos, uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
