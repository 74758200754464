/* eslint-disable @typescript-eslint/ban-ts-ignore */
import addresses from 'constants/config/contracts'
import { Address } from 'constants/config/types'

export const getAddress = (address: Address): string => {
  // @ts-ignore
  let chainId = window && window.ethereum ? window.ethereum.networkVersion : process.env.REACT_APP_CHAIN_ID
  if (localStorage && localStorage.getItem('chainId')) {
    chainId = localStorage.getItem('chainId')
  }
  // @ts-ignore
  // samarth
  return address['80001']
}
export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall)
}
export const getWbnbAddress = () => {
  return getAddress(addresses.wbnb)
}
