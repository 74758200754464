/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-nocheck
import Web3 from 'web3'
import { Token } from '@uniswap/sdk'
import pools from 'constants/config/farms'
import vaults from 'constants/config/vaults'
import sousChefABI from 'constants/abis/sousChef.json'
import vaultABI from 'constants/abis/vault.json'
import sousChefABIDeposit from 'constants/abis/sousChefDepost.json'
import wbnbABI from 'constants/abis/weth.json'
import { QuoteToken } from 'constants/config/types'
import erc20 from 'constants/abis/erc20.json'
import multicall from 'utils/multicall'
import { getAddress, getWbnbAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'

// @ts-ignore
const poolsConfig = pools.concat(vaults)

const web3 = new Web3(window.ethereum)
export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter(p => p.sousId !== 0)

  const poolsWithEndDepositFee = poolsConfig.filter(p => p.sousId !== 0 && p.sousId >= 8)

  // eslint-disable-next-line array-callback-return
  const callsFarmInfo = poolsWithEnd.map(poolConfig => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'farmInfo'
    }
  })

  const starts = await multicall(sousChefABI, callsFarmInfo)
  const ends = await multicall(sousChefABI, callsFarmInfo)

  const callsFarmWithDepsoitInfo = poolsWithEndDepositFee.map(poolConfig => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'farmInfo'
    }
  })

  const startWithDepostiFee = await multicall(sousChefABIDeposit, callsFarmWithDepsoitInfo)
  // const endsWithDepostiFee = await multicall(sousChefABIDeposit, callsFarmWithDepsoitInfo);

  return poolsConfig.map((cakePoolConfig, index) => {
    if (cakePoolConfig.sousId === 0) {
      return {
        sousId: cakePoolConfig.sousId,
        startBlock: new BigNumber('0').toJSON(),
        endBlock: new BigNumber('0').toJSON()
      }
      // eslint-disable-next-line no-else-return
    }

    const startBlock = starts[index - 1].startBlock._hex
    const endBlock = ends[index - 1].endBlock._hex
    const poolHarvestIntervall = starts[index - 1].harvestInterval._hex
    const poolwithdrawalFeeBP = starts[index - 1].withdrawalFeeBP
    let depositFee = 0

    if (cakePoolConfig.sousId >= 8) {
      depositFee = startWithDepostiFee[index - 1 - 7].depositFeeBP
    }

    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
      poolHarvestInterval: new BigNumber(poolHarvestIntervall).toJSON(),
      poolwithdrawalFeeBP: new BigNumber(poolwithdrawalFeeBP).toJSON(),
      pooldepositFeeBP: new BigNumber(depositFee).toJSON()
    }
  })
}

export const fetchPoolsTotalStatking = async () => {
  const poolsWithEnd = poolsConfig.filter(p => p.sousId !== 0)

  const nonBnbPools = poolsWithEnd.filter(p => p.stakingTokenName !== QuoteToken.BNB)
  const bnbPool = poolsWithEnd.filter(p => p.stakingTokenName === QuoteToken.BNB)

  const callsNonBnbPools = nonBnbPools.map(poolConfig => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)]
    }
  })

  const callsBnbPools = bnbPool.map(poolConfig => {
    return {
      address: getWbnbAddress(),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)]
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(sousChefABI, callsNonBnbPools)
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools)

  return [
    {
      sousId: 0
    },
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON()
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON()
    }))
  ]
}

export const fetchPoolsLpData = async () => {
  const nonBnbPools = poolsConfig.filter(p => p.stakingTokenName !== QuoteToken.BNB)

  const data = await Promise.all(
    nonBnbPools.map(async farmConfig => {
      const lpAdress = farmConfig.stakingTokenAddress
      const calls = [
        // Balance of token in the LP contract
        {
          address: farmConfig.tokenAdressInLp,
          name: 'balanceOf',
          params: [lpAdress]
        },
        // Balance of quote token on LP contract
        {
          address: farmConfig.tokenAdressInLp,
          name: 'balanceOf',
          params: [lpAdress]
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAdress,
          name: 'balanceOf',
          params: [getAddress(farmConfig.contractAddress)]
        },
        // Total supply of LP tokens
        {
          address: lpAdress,
          name: 'totalSupply'
        },
        // Token decimals
        {
          address: farmConfig.tokenAddress,
          name: 'decimals'
        },
        // Token decimals
        {
          address: farmConfig.tokenAdressInLp,
          name: 'decimals'
        },
        {
          address: farmConfig.tokenAddress,
          name: 'name'
        },
        // Quote token name
        {
          address: farmConfig.tokenAdressInLp,
          name: 'name'
        },
        {
          address: farmConfig.tokenAddress,
          name: 'symbol'
        },
        // Quote token name
        {
          address: farmConfig.tokenAdressInLp,
          name: 'symbol'
        }
      ]
      const [
        tokenBalanceLP,
        quoteTokenBlanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
        tokenName,
        quoteTokenName,
        tokenSymbol,
        quoteTokenSymbol
        // @ts-ignore
      ] = await multicall(erc20, calls)
      // console.log({
      //   tokenName: tokenName.toString(),
      //   quoteTokenName: quoteTokenName.toString(),
      //   tokenSymbol: tokenSymbol.toString(),
      //   quoteTokenSymbol: quoteTokenSymbol.toString(),
      //   tokenDecimals: tokenDecimals.toString(),
      //   quoteTokenDecimals: quoteTokenDecimals.toString()
      // })
      let chainId = window && window.ethereum ? window.ethereum.networkVersion : process.env.REACT_APP_CHAIN_ID
      if (localStorage && localStorage.getItem('chainId')) {
        chainId = localStorage.getItem('chainId')
      }
      if (chainId === null || chainId === undefined) {
        chainId = '80001'
      }
      const token0 = new Token(
        chainId,
        farmConfig.tokenAddress,
        tokenDecimals,
        tokenSymbol ? tokenSymbol : 'UNKNOWN',
        tokenName ? tokenName : 'Unknown Token'
      )
      const token1 = new Token(
        chainId,
        farmConfig.tokenAdressInLp,
        quoteTokenDecimals,
        quoteTokenSymbol ? quoteTokenSymbol : 'UNKNOWN',
        quoteTokenName ? quoteTokenName : 'Unknown Token'
      )
      const tokens = [token0, token1]
      let rewardPerDay = 0
      const periodFinish = '0'

      if (!farmConfig.isVault) {
        const callsRewards = [
          {
            address: getAddress(farmConfig.contractAddress),
            name: 'poolInfo',
            params: [farmConfig.sousId]
          },
          {
            address: getAddress(farmConfig.contractAddress),
            name: 'totalAllocPoint'
          },
          {
            address: getAddress(farmConfig.contractAddress),
            name: 'WorldOfDefiPerBlock'
          }
        ]
        const [info, totalAllocPoint, WorldOfDefiPerBlock] = await multicall(sousChefABI, callsRewards)
        const allocPoint = new BigNumber(info.allocPoint._hex)
        const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
        rewardPerDay = (parseFloat(WorldOfDefiPerBlock.toString()) / 1e18) * parseFloat(poolWeight) * 43200
      }

      let tokenAmount
      let lpTotalInQuoteToken
      let tokenPriceVsQuote
      // if (farmConfig.isTokenOnly) {
      //   const REALBALANCE = new BigNumber(10000000)
      //   const realbalance = parseFloat(REALBALANCE.toString()) - 2000000
      //   console.log(REALBALANCE.toString())
      //   tokenAmount = new BigNumber(REALBALANCE.toString())
      //   if (farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD) {
      //     tokenPriceVsQuote = new BigNumber(1)
      //   } else {
      //     tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
      //   }
      //   lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      // } else {
      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
      // Total value in staking in quote token value
      lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(new BigNumber(2))
        .times(lpTokenRatio)

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
      const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)
      const quoteTokeFirstAmount = new BigNumber(quoteTokenBlanceLP)
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio)
      // }
      let vaultApy = 0
      let availableLimit = 0
      let vaultLimit = 0
      let vaultTotalDeposited = 0
      let vaultLaunchPad = 0
      let vaultStakingTime = 0
      let withdrawEnableTimestamp = 0
      if (farmConfig.isVault) {
        const callVaults = [
          {
            address: getAddress(farmConfig.contractAddress),
            name: 'vaultStartTime'
          },
          {
            address: getAddress(farmConfig.contractAddress),
            name: 'totalTokenStaked'
          },
          {
            address: getAddress(farmConfig.contractAddress),
            name: 'vaultLockLimit'
          },
          {
            address: getAddress(farmConfig.contractAddress),
            name: 'stakingTime'
          },
          {
            address: getAddress(farmConfig.contractAddress),
            name: 'rewardPercent'
          }
        ]
        const [launchDate, totalDeposited, vaultLockLimit, stakingTime, rewardPercent] = await multicall(
          vaultABI,
          callVaults
        )
        vaultApy = parseFloat(rewardPercent.toString()) / 100
        vaultLimit = parseFloat(web3.utils.fromWei(vaultLockLimit.toString(), 'ether'))
        vaultTotalDeposited = parseFloat(web3.utils.fromWei(totalDeposited.toString(), 'ether'))
        availableLimit = parseFloat(vaultLimit) - parseFloat(vaultTotalDeposited)
        vaultLaunchPad = launchDate.toString()
        vaultStakingTime = parseFloat(stakingTime.toString())
        withdrawEnableTimestamp = parseFloat(launchDate.toString()) + parseFloat(stakingTime.toString()) * 24 * 60 * 60
      }
      return {
        ...farmConfig,
        vaultApy,
        vaultLimit,
        periodFinish,
        vaultTotalDeposited,
        availableLimit,
        vaultLaunchPad,
        withdrawEnableTimestamp,
        vaultStakingTime,
        tokens,
        tokenAmount: tokenAmount.toJSON(),
        rewardPerDay: rewardPerDay.toFixed(2),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        totalDeposited: lpTokenBalanceMC.toString(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        quoteTokeFirstAmount: quoteTokeFirstAmount.toJSON(),
        tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON()
      }
    })
  )

  return data
}
