// @ts-nocheck
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAddress } from 'utils/addressHelpers'
import useRefresh from 'hooks/useRefresh'
import CoinGecko from 'coingecko-api'
import { fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'
import { State, Pool } from './types'
import BigNumber from 'bignumber.js'

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    //   dispatch(fetchFarmsPublicDataAsync());
    dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])

  // useEffect(() => {
  //   const web3 = getWeb3NoAccount()
  //   const interval = setInterval(async () => {
  //     const blockNumber = await web3.eth.getBlockNumber()
  //     dispatch(setBlock(blockNumber))
  //   }, 30000)

  //   return () => clearInterval(interval)
  // }, [dispatch])
}

export const fetchPrice = async (crypto: string) => {
  const CoinGeckoClient = new CoinGecko()
  const result = await CoinGeckoClient.coins.fetch(crypto.toLocaleLowerCase(), {})
  const res = new BigNumber(result.data?.market_data?.current_price?.usd)
  return res
}

export const usePools = (account: string): Pool[] => {
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromContractAddress = (address: string): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find(p => getAddress(p.contractAddress) === address))
  // @ts-ignore
  return pool
}
