// @ts-nocheck
import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import Web3 from 'web3'
// import { Link } from 'react-router-dom'
import { getAddress } from 'utils/addressHelpers'
import { RouteComponentProps } from 'react-router-dom'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'
import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
import { ButtonPrimary, ButtonEmpty } from '../../components/Button'
import StakingModalVault from '../../components/earn/StakingModalVaultNew'
import { usePools } from 'state/hooks'
import BigNumber from 'bignumber.js'
import UnstakingModalVault from '../../components/earn/UnstakingModalVaultNew'
// import ClaimRewardModalVault from '../../components/earn/ClaimRewardModalVault'
import { useActiveWeb3React } from '../../hooks'
// import SingleCurrencyLogo from 'components/SingleCurrencyLogo'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${showBackground ? theme.black : theme.bg5} 100%) `};
`

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg3};
  opacity: ${({ dim }) => (dim ? 0.4 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function ManageVault({
  match: {
    params: { contract }
  }
}: RouteComponentProps<{ contract: string }>) {
  const { account, chainId } = useActiveWeb3React()

  let userStakedTokens = 0
  const web3 = new Web3(window.ethereum)
  const pools = usePools(account)
  const timestamp = Math.floor(parseFloat(+new Date()) / 1000)
  let poolInfo = null
  if (pools && pools.length > 0) {
    poolInfo = pools.find(p => getAddress(p.contractAddress) === contract)
  }
  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = '0'
  const showAddLiquidityButton = false
  let disableTop = false
  if (poolInfo && poolInfo.userData && poolInfo.userData.vaultDepositInfo && userStakedTokens === 0) {
    // showAddLiquidityButton = true
    // fade cards if nothing staked or nothing earned yet
    disableTop = true
  }
  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // const token = stakingInfo?.[0]?.token
  // const WETH = ETHER
  const backgroundColor = 'rgb(33, 114, 229)'
  // get WETH value of staked LP tokens
  // const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  // let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  // if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
  //   // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
  //   valueOfTotalStakedAmountInWETH = new TokenAmount(
  //     WETH,
  //     JSBI.divide(
  //       JSBI.multiply(
  //         JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
  //         JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
  //       ),
  //       totalSupplyOfStakingToken.raw
  //     )
  //   )
  // }

  // fix-sanchit
  // const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  // const countUpAmount = '0' // wrong
  let earnedAmount = '0'
  if (
    account &&
    poolInfo &&
    poolInfo.vaultApy &&
    poolInfo.userData &&
    poolInfo.userData.vaultDepositInfo &&
    poolInfo.userData.vaultDepositInfo.toString()
  ) {
    //     try{
    //  const userLiquidity =  web3.utils.fromWei(poolInfo.userData.vaultDepositInfo, 'ether')
    //     earnedAmount = (parseFloat(userLiquidity) * parseFloat(poolInfo.vaultApy)) / 100
    //     }catch(e)
    //     {

    //     }
    // const aa = await web3.utils.toBN(poolInfo?.userData?.vaultDepositInfo)
    //  window.alert(poolInfo?.userData?.vaultDepositInfo.toLocaleString('fullwide', {useGrouping:false}))
    const aa = new BigNumber(poolInfo?.userData?.vaultDepositInfo.toString(16))
    const aaa = web3.utils.toBN(aa)

    // window.alert(aa)
    const userLiquidity = web3.utils.fromWei(aaa, 'ether')

    userStakedTokens = userLiquidity
    // window.alert(userLiquidity)
    earnedAmount = (parseFloat(userLiquidity) * parseFloat(poolInfo.vaultApy)) / 100
  }
  // const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'
  //  console.log(JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.[0]?.earnedAmount?.raw),"unclaimed")
  // get the USD value of staked WETH
  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  // const mp: any = stakingInfo?.[0]?.maturityPeriod
  if (poolInfo) {
    return (
      <PageWrapper gap="lg" justify="center">
        <RowBetween style={{ gap: '24px' }}>
          <TYPE.mediumHeader style={{ margin: 0 }}>{poolInfo.quoteTokenSymbol} Mining</TYPE.mediumHeader>
          {/* <SingleCurrencyLogo currency0={currencyA ?? undefined} size={24} /> */}
        </RowBetween>

        <DataRow style={{ gap: '24px' }}>
          <PoolData>
            <AutoColumn gap="sm">
              <TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
              <TYPE.body fontSize={24} fontWeight={500}>
                {poolInfo && poolInfo.vaultTotalDeposited ? `${poolInfo.vaultTotalDeposited.toString()} ` : '0 '}
                {poolInfo.stakingTokenName}
              </TYPE.body>
            </AutoColumn>
          </PoolData>
          <PoolData>
            <AutoColumn gap="sm">
              <TYPE.body style={{ margin: 0 }}>APR</TYPE.body>
              <TYPE.body fontSize={24} fontWeight={500}>
                {poolInfo && poolInfo.vaultApy ? poolInfo.vaultApy : '0'} %
              </TYPE.body>
            </AutoColumn>
          </PoolData>
          <PoolData>
            <AutoColumn gap="sm">
              <TYPE.body style={{ margin: 0 }}> Maturity Period</TYPE.body>
              <TYPE.body fontSize={24} fontWeight={500}>
                {poolInfo && poolInfo.vaultStakingTime} {` Days `}
              </TYPE.body>
              {/* <TYPE.body fontSize={24} fontWeight={500}>
              {stakingInfo?.totalRewardRate2
                ?.multiply((60 * 60 * 24 * 7).toString())
                ?.toFixed(5, { groupSeparator: ',' }) ?? ' - '}
              &nbsp;{currencyB?.symbol}
              {'   / week'}
            </TYPE.body> */}
            </AutoColumn>
          </PoolData>
        </DataRow>

        {showAddLiquidityButton && (
          <VoteCard>
            <CardBGImage />
            <CardNoise />
            <CardSection>
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Step 1. Get {poolInfo.stakingTokenName} Liquidity tokens</TYPE.white>
                </RowBetween>
                <RowBetween style={{ marginBottom: '1rem' }}>
                  <TYPE.white fontSize={14}>
                    {`${poolInfo.stakingTokenName} tokens are required. Once you've added token to the ${poolInfo.stakingTokenName} vault you can stake your token on this page.`}
                  </TYPE.white>
                </RowBetween>
                {/* <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                width={'fit-content'}
                as={Link}
                to={`/add/${currencyA && currencyId(currencyA)}/${WETH && currencyId(currencyB)}`}
              >
                {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
              </ButtonPrimary> */}
              </AutoColumn>
            </CardSection>
            <CardBGImage />
            <CardNoise />
          </VoteCard>
        )}

        {poolInfo && (
          <>
            <StakingModalVault
              isOpen={showStakingModal}
              onDismiss={() => setShowStakingModal(false)}
              poolInfo={poolInfo}
              userLiquidityUnstaked={userLiquidityUnstaked}
            />
            <UnstakingModalVault
              isOpen={showUnstakingModal}
              onDismiss={() => setShowUnstakingModal(false)}
              poolInfo={poolInfo}
            />
            {/*<ClaimRewardModalVault
              isOpen={showClaimRewardModal}
              onDismiss={() => setShowClaimRewardModal(false)}
              vaultInfo={poolInfo}
            /> */}
          </>
        )}

        <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
          <BottomSection gap="lg" justify="center">
            <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
              <CardSection>
                <CardBGImage desaturate />
                <CardNoise />
                <AutoColumn gap="md">
                  <RowBetween>
                    <TYPE.white fontWeight={600}>Your {poolInfo.stakingTokenName} deposits</TYPE.white>
                  </RowBetween>
                  <RowBetween style={{ alignItems: 'baseline' }}>
                    <TYPE.white fontSize={36} fontWeight={600}>
                      {poolInfo &&
                      poolInfo.userData &&
                      poolInfo.userData.vaultDepositInfo &&
                      poolInfo.userData.vaultDepositInfo.toString()
                        ? userStakedTokens
                        : '0'}
                    </TYPE.white>
                    <TYPE.white>{poolInfo.stakingTokenName}</TYPE.white>
                  </RowBetween>
                </AutoColumn>
              </CardSection>
            </StyledDataCard>
            <StyledBottomCard
              dim={poolInfo && poolInfo.userData && poolInfo.userData.vaultDepositInfo && userStakedTokens === 0}
            >
              <CardBGImage desaturate />
              <CardNoise />
              <AutoColumn gap="sm">
                <RowBetween>
                  <div>
                    <TYPE.black>Your Unclaimed {poolInfo.quoteTokenSymbol}</TYPE.black>
                  </div>
                  {/* // fix sanchit */}
                  {/* {parseFloat(earnedAmount) > 0 && (
                    <ButtonEmpty
                      padding="8px"
                      borderRadius="8px"
                      width="fit-content"
                      onClick={() => setShowClaimRewardModal(true)}
                    >
                      Claim
                    </ButtonEmpty>
                  )} */}
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.white fontSize={36} fontWeight={600}>
                    {earnedAmount ?? '-'}
                  </TYPE.white>
                  {/* <TYPE.largeHeader fontSize={36} fontWeight={600}>
               
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader> */}
                  <TYPE.black fontSize={16} fontWeight={500}>
                    {/* <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span> */}
                    {/* {stakingInfo?.rewardRate
                    ?.multiply((60 * 60 * 24 * 7).toString())
                    ?.toSignificant(4, { groupSeparator: ',' }) ?? '-'}
                  &nbsp; ${currencyA?.symbol} */}
                  </TYPE.black>
                </RowBetween>
                {/* <RowBetween style={{ alignItems: 'baseline' }}>
                <TYPE.largeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount2}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious2)}
                    end={parseFloat(countUpAmount2)}
                    thousandsSeparator={','}
                    duration={1}
                  />
                </TYPE.largeHeader>
                <TYPE.black fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>
                  {stakingInfo?.totalRewardRate2
                    ?.multiply((60 * 60 * 24 * 7).toString())
                    ?.toSignificant(4, { groupSeparator: ',' }) ?? '-'}
                  &nbsp; ${currencyB?.symbol}
                  {'  / week'}
                </TYPE.black>
              </RowBetween> */}
              </AutoColumn>
            </StyledBottomCard>
          </BottomSection>
          {/* timestamp < parseFloat(poolInfo.vaultLaunchPad) &&  */}
          {poolInfo.userData && poolInfo.userData.vaultDepositInfo && (
            <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
              <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
                ⭐️
              </span>
              Tokens staked and rewards can be claimed after{' '}
              {new Date(poolInfo.withdrawEnableTimestamp * 1000).toDateString()}
            </TYPE.main>
          )}

          {!showAddLiquidityButton &&
            poolInfo &&
            poolInfo.vaultLaunchPad &&
            timestamp < parseFloat(poolInfo.vaultLaunchPad) && (
              <DataRow style={{ marginBottom: '1rem' }}>
                <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleDepositClick}>
                  {poolInfo.userData && poolInfo.userData.vaultDepositInfo && userStakedTokens > 0
                    ? 'Deposit'
                    : 'Deposit Chilli-LP Tokens'}
                </ButtonPrimary>

                {poolInfo.userData &&
                  poolInfo.userData.vaultDepositInfo &&
                  userStakedTokens > 0 &&
                  timestamp > parseFloat(poolInfo.withdrawEnableTimestamp) && (
                    <>
                      <ButtonPrimary
                        padding="8px"
                        borderRadius="8px"
                        width="160px"
                        onClick={() => setShowUnstakingModal(true)}
                      >
                        Withdraw
                      </ButtonPrimary>
                    </>
                  )}
              </DataRow>
            )}
          {/* {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
          <TYPE.main>{userLiquidityUnstaked.toSignificant(6)} Chilli-LP tokens available</TYPE.main>
        )} */}
        </PositionInfo>
      </PageWrapper>
    )
  }
  return <div>Loading...</div>
}
